import React from "react";

import PageHead from "@Components/PageHead";

function opensource() {
  return (
    <>
      <PageHead>
        <h2 className="head-title">
          We <span>love</span> open Source
        </h2>
      </PageHead>
    </>
  );
}

export default opensource;
